
.pid-container {
    padding: 30px 20px 90px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        height: 1px;
        background: #ddd;
    }
}
.prompt-text {
    margin-left: 10px;
    color: #666;
}
.el-link + .el-link {
    margin-left: 10px;
}
.yellow-prompt {
    margin-top: 1px;
    color: #666;
    font-size: 12px;
    background: #FCF8E3;
    text-align: center;
    padding: 10px 0;
}

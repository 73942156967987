
.pid-tab {
    display: flex;
    .tab-item {
        font-size: 16px;
        line-height: 20px;
        padding: 0 20px;
        cursor: pointer;
        transition: all .3s;
        &.current {
            color: #FE4800;
        }
        &:first-child {
            padding-left: 0;
        }
    }
}


.main-container {
    position: relative;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px 10px 0 0;
    .main-wrapper {
        height: 1%;
        flex: 1;
        display: flex;
        flex-direction: column;
        .search-wrapper {
            position: relative;
            .search-input {
                margin: 45px auto 0;
                display: flex;
                align-items: center;
                width: 730px;
                ::v-deep .el-input__inner {
                    width: 450px;
                    color: #222;
                    border-color: #FF7B4B;
                }
                ::v-deep .el-input-group__append, ::v-deep .el-input-group__prepend {
                    padding: 0;
                    border: 0;
                    overflow: hidden;
                    display: flex;
                    width: auto;
                    .search-btn {
                        background-color: #FE4800;
                        border-color: #FE4800;
                        color: #fff;
                        font-size: 16px;
                        cursor: pointer;
                        padding: 12px 20px;
                        line-height: 16px;
                    }
                }
            }
            .blue-btn {
                position: absolute;
                top: 49px;
                right: 50px;
            }
        }
        .list-wrapper {
            height: 1%;
            flex: 1;
            margin-top: 55px;
            display: flex;
            flex-direction: column;
            .list-tab {
                background: #EFF0FF;
                padding: 0 100px;
                display: flex;
                justify-content: space-between;
                .tab-item {
                    font-size: 16px;
                    line-height: 50px;
                    cursor: pointer;
                    position: relative;
                    transition: all .3s;
                    &.current {
                        font-size: 18px;
                        font-weight: 500;
                        &:before {
                            content: '';
                            position: absolute;
                            left: 50%;
                            bottom: 2px;
                            width: 0;
                            height: 0;
                            transform: translateX(-50%);
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-bottom: 4px solid #9ACC5B;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 2px;
                            background: #9ACC5B;
                        }
                    }
                }
            }
            .list-container {
                flex: 1;
                height: 1%;
                ::v-deep > .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .list-content {
                    padding: 10px 100px 16px 50px;
                    display: flex;
                    flex-wrap: wrap;
                }
                .good-item {
                    width: calc(16.666666% - 50px);
                    margin: 30px 0 0 50px;
                    position: relative;
                    .good-rank {
                        position: absolute;
                        top: -7px;
                        left: 10px;
                        z-index: 1;
                        color: #FAE46A;
                        display: none;
                        .iconfont {
                            font-size: 58px;
                            color: #EDB151;
                        }
                        .top {
                            position: absolute;
                            top: 6px;
                            width: 62px;
                            text-align: center;
                            font-size: 16px;
                            font-weight: 700;
                        }
                        .num {
                            position: absolute;
                            top: 26px;
                            width: 62px;
                            text-align: center;
                            font-size: 24px;
                            font-weight: 600;
                        }
                    }
                    &:nth-of-type(1) {
                        .good-rank {
                            display: block;
                        }
                    }
                    &:nth-of-type(2) {
                        .good-rank {
                            display: block;
                            color: #E6EEF5;
                            .iconfont {
                                color: #B2C7D8;
                            }
                        }
                    }
                    &:nth-of-type(3) {
                        .good-rank {
                            display: block;
                            color: #F7D6A6;
                            .iconfont {
                                color: #D3AD6F;
                            }
                        }
                    }
                    .good-cover {
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                        position: relative;
                        overflow: hidden;
                        background: #EFF0FF;
                        .cover-wrapper {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            //top: 50%;
                           // left: 50%;
                            //transform: translate(-50%, -50%);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .good-operate {
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            display: flex;
                            .o-item {
                                width: 50%;
                                color: #fff;
                                text-align: center;
                                line-height: 28px;
                                background: rgba(255, 82, 0, .8);
                                position: relative;
                                cursor: pointer;
                                font-size: 13px;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    top: 9px;
                                    right: 0;
                                    width: 1px;
                                    height: 10px;
                                    background: #fff;
                                }
                                &:last-child {
                                    &:after {
                                        content: none;
                                    }
                                }
                                &.gray {
                                    color: #333;
                                    background: rgba(221, 221, 221, .9);
                                }
                            }
                        }
                    }
                    .good-detail {
                        padding: 12px 7px;
                        box-shadow: 0 0 16px 1px rgba(83, 73, 218, 0.2);
                    }
                    .good-name {
                        color: #222;
                        transition: all .3s;
                        height: 40px;
                        &:hover {
                            color: #FE4800;
                        }
                    }
                    .good-sale {
                        margin: 25px 0;
                        color: #666;
                        text-align: center;
                    }
                    .good-process {
                        margin-top: 25px;
                        ::v-deep .el-progress-bar__outer {
                            background-color: #FFD3C2;
                        }
                    }
                    .good-line {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        .l-item {
                            text-align: center;
                            .line1, .line2 {
                                line-height: 1;
                            }
                            .line2 {
                                color: #666;
                                font-size: 12px;
                                margin-top: 6px;
                            }
                        }
                    }
                    .good-ticket {
                        margin-top: 8px;
                        display: flex;
                        align-items: center;
                        .ticket-left {
                            width: 22px;
                            background: #EC6941;
                            font-size: 12px;
                            color: #fff;
                            text-align: center;
                            line-height: 20px;
                            border-radius: 2px 0 0 2px;
                        }
                        .right {
                            font-size: 12px;
                            color: #FE4800;
                            border: 1px solid #EC6941;
                            height: 20px;
                            padding: 0 6px;
                            box-sizing: border-box;
                            border-radius: 0 2px 2px 0;
                        }
                    }
                    .store-name {
                        font-size: 12px;
                        margin-top: 12px;
                        text-align: right;
                    }
                }
            }
        }
    }
}
.orange {
    color: #FE4800;
}
.tab-group {
    display: flex;
    flex-wrap: wrap;
    padding: 0 100px 0 80px;
    .tab-btn {
        font-size: 16px;
        line-height: 30px;
        padding: 0 20px;
        background: #EFF0FF;
        border-radius: 4px;
        margin-left: 20px;
        margin-top: 20px;
        cursor: pointer;
        transition: all .3s;
        &:hover, &.current {
            color: #fff;
            background: #FE4800;
        }
    }
}
.class-group {
    display: flex;
    padding: 0 100px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 1;
    .group-left {
        font-weight: 500;
        margin-top: 20px;
    }
    .group-right {
        width: 1%;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        margin-left: 20px;
        .class-item {
            cursor: pointer;
            margin-left: 20px;
            margin-top: 20px;
            transition: all .3s;
            &:hover, &.current {
                color: #FE4800;
            }
        }
    }
}
.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 100px;
    img {
        height: 100px;
    }
    .text {
        color: #787D9B;
        line-height: 1;
        margin-top: 50px;
    }
}
